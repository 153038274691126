<template>
  <FormulasGodApeQuestItems
    :items="items"
    v-if="tab === 'god_ape_quest'"
    @select="selectFormula"
  />
  <FormulasApeTicketItems
    :items="items"
    v-else-if="tab === 'ape_ticket'"
    @select="selectFormula"
  />
  <div class="formulas-items" v-else>
    <div class="formulas-items__item" v-for="item in items" :key="item">
      <FormulasFormulaCard :item="item" @click.prevent="selectFormula(item)" />
    </div>
  </div>
</template>
<script setup>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { useFormulasStore } from "@/store/formulas/formulas";
import { computed, ref } from "vue";
import FormulasFormulaCard from "../FormulasFormulaCard/FormulasFormulaCard.vue";
import FormulasGodApeQuestItems from "./FormulasGodApeQuestItems.vue";
import FormulasApeTicketItems from "./FormulasApeTicketItems.vue";
const formulasStore = useFormulasStore();
const props = defineProps({
  tab: String,
});
const items = computed(() => {
  const items = formulasStore.formulas;
  if (props.tab === "god_ape_quest") {
    return items.filter((item) => !!item.team_type);
  }
  if (props.tab === "ape_ticket") {
    return items.filter(
      (item) =>
        typeof item.combination_id === "string" &&
        item.combination_id.startsWith("AT")
    );
  }
  return items;
});
const selectFormula = (item) => {
  formulasStore.selectFormula(item.combination_id);
  if (window.innerWidth < 1200) {
    const top = document.querySelector(".formulas-preview").offsetTop - 60;
    window.scrollTo({ top, behavior: "smooth" });
  }
};
</script>
<style lang="scss">
.formulas-items {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -10px;
  margin-top: 20px;
  position: relative;
  max-height: 80vh;
  overflow: auto;
  &__item {
    margin: 10px;
    width: calc(100% / 3 - 20px);
  }

  @media screen and (max-width: 1400px) {
    &__item {
      width: calc(100% / 2 - 20px);
    }
  }
  @media screen and (max-width: 1200px) {
    &__item {
      width: calc(100% / 3 - 20px);
    }
  }
  @media screen and (max-width: 768px) {
    &__item {
      width: calc(100% / 2 - 20px);
    }
  }
  @media screen and (max-width: 576px) {
    margin: 0 -5px;
    padding: 5px;
    margin-top: 20px;
    &__item {
      width: 100%;
      margin: 10px 0;
    }
  }
}
</style>
